import React, {useState, useEffect, useRef, useContext, useCallback} from 'react'
import dayjs from "dayjs";
import {Form, Input, Button, Upload, message, Tag, Select, Tooltip} from 'antd';
import {
    UserOutlined,
    UploadOutlined,
    MailOutlined,
    EditOutlined,
    SafetyOutlined,
    EyeOutlined,
    CheckOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {SpinnerContext} from '../../../context/SpinnerContext';
import {uploadManifest} from "../../../services/management";
import useManifestWizard from "../hooks/useManifestWizard";
import {
    ConvertFileKeyToPem,
    ConvertFileCertToPem,
    ConvertFileToBase64,
    GetCertificateMeta,
    EncodeB64,
    SignWithRSAKey,
    VerifyWithRSACertificate
} from "../../../common/crypto";

const ManifestForm = (props) => {
    const {setSpinnerLoading} = useContext(SpinnerContext);
    const [form] = Form.useForm();
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [messageApi, contextHolder] = message.useMessage();
    const {
        setManifestTemplate,
        setManifiestPdf,
        setOpenModalManifestSuccess,
        setManifestEmail,
        nextWizardStep,
        manifestText,
        wizardStep,
        wizardPrevStep,
    } = useManifestWizard();
    var _manifestTemplate = manifestText;
    const [isLoading, setIsLoading] = useState({ loading: false, message: "" });
    const [certificate, setCertificate] = useState({ b64: '', pem: '', filename: '', modulus: '' })
    const [privateKey, setPrivateKey] = useState({ pem: '', filename: '', modulus: '' })
    const [taxId, setTaxId] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const inputEmailRef = useRef(null);

    const emailChange = (value) => setEmail(value.join(','))
    const passwordChange = (evt) => setPassword(evt.target.value)
    const hasFormErrors = form.getFieldsError().filter(({errors}) => errors.length).length > 0;

    useEffect(() => {
        if (inputEmailRef.current)
            inputEmailRef.current.focus();
    }, [inputEmailRef]);

    useEffect(() => {
        setSpinnerLoading({isLoading: isLoading.loading, text: isLoading.message});
    }, [isLoading]);

    const handleSubmit = useCallback(async (values) => {
        try {
            if (!executeRecaptcha)
                throw "Actualize sitio web y reintente de nuevo.";
            const captchaToken = await executeRecaptcha('manifest');

            setIsLoading({loading: true, message: 'Procesando Carta Manifiesto'});

            var {
                signatureHex,
                signatureB64,
                modulus: keyModulus
            } = SignWithRSAKey(_manifestTemplate, privateKey.pem, password);
            var isSignatureValid = VerifyWithRSACertificate(certificate.pem, _manifestTemplate, signatureHex)
            if (!isSignatureValid)
                throw 'Llave privada o certificado no válido.';
            if (keyModulus !== certificate.modulus)
                throw 'Llave privada o certificado no se pertenecen.';

            let payload = {
                B64Cer: certificate.b64,
                B64ManifestText: EncodeB64(_manifestTemplate),
                B64ManifestSignature: signatureB64,
                SendEmail: true,
                Email: email
            }
            var response = await uploadManifest(payload, captchaToken);
            if (!response?.ok) {
                setIsLoading({loading: false, message: ''});
                let responseBody = response?.body != null ? await response.json() : '';
                messageApi.error(`Error al tratar de subir carta manifiesto, ${responseBody?.messageDetail || responseBody?.message || responseBody}`, 3000);
                return;
            }

            setIsLoading({loading: false, message: ''});
            messageApi.success(`Carta Manifiesto procesada correctamente.`);
            var pdfB64 = await ConvertFileToBase64(await response.blob())
            setManifiestPdf(pdfB64);
            setManifestEmail(email);
            setOpenModalManifestSuccess();
        } catch (e) {
            setIsLoading({loading: false, message: ''});
            if (e.message?.includes("malformed plain PKCS8 private key"))
                messageApi.error(`Error al tratar de firmar carta manifiesto, contraseña de llave privada incorrecta.`);
            else
                messageApi.error(`Error al tratar de firmar carta manifiesto, ${e}`);
        }
    }, [executeRecaptcha, certificate, privateKey, password, email]);

    const propsUploadCer = {
        customRequest: async ({file, data, onSuccess, onError}) => {
            // Handle the file upload manually, you can use XMLHttpRequest or any other method here.
            onSuccess();

            let now = new Date();
            let certB64 = await ConvertFileToBase64(file);
            let certPem = await ConvertFileCertToPem(file);

            let {taxId: _taxId, companyName: _companyName, validity, isCsd, modulus} = GetCertificateMeta(certPem);
            if (isCsd) {
                messageApi.error(`El certificado '${file.name}' no es Fiel/eFirma.`);
                return;
            }
            if (validity.notBefore > now) {
                messageApi.error(`El certificado '${file.name}' no esta vigente, fecha de inicio de vigencia ${dayjs(validity.notAfter).format("DD-MM-YYYY HH:mm:ss")}`);
                return;
            }
            if (validity.notAfter < now) {
                messageApi.error(`El certificado '${file.name}' no esta vigente, fecha de vencimiento ${dayjs(validity.notAfter).format("DD-MM-YYYY HH:mm:ss")}`);
                return;
            }
            setTaxId(_taxId.trim());
            setCompanyName(_companyName.trim());
            setCertificate({b64: certB64.split(",")[1], pem: certPem, filename: file.name, modulus: modulus});
            form.setFieldValue("taxId", _taxId)

            _manifestTemplate = _manifestTemplate.replace("<nombre o razón social>", _companyName).replace("<rfc>", _taxId);
            setManifestTemplate(_manifestTemplate);
            form.setFieldValue("cer", file.name);
            messageApi.success(`Certificado '${file.name}' cargado correctamente.`);
        },
        // Return false to prevent automatic upload
        beforeUpload: (file) => {
            setIsLoading({loading: true, message: 'Subiendo Archivo Certificado.'})
            let extension = file.name.split(".").pop();
            //todo: validar extension y otros mime types, file.type === "application/x-x509-ca-cert" && 
            const isCert = extension == "cer";
            if (!isCert) {
                setIsLoading({loading: false, message: ''})
                messageApi.error(`El archivo '${file.name}' no es extensión *.cer`);
                return false;
            }
            setIsLoading({loading: false, message: ''})
            return isCert || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            //if (info.file.status !== 'uploading') setIsLoading(true);
            if (info.file.status === 'done') setIsLoading({loading: false, message: ''});
            else if (info.file.status === 'error') {
                setIsLoading({loading: false, message: ''});
                messageApi.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const propsUploadKey = {
        customRequest: async ({file, data, onSuccess, onError}) => {
            // Handle the file upload manually, you can use XMLHttpRequest or any other method here.
            onSuccess();

            var keyPem = await ConvertFileKeyToPem(file);
            setPrivateKey({pem: keyPem, filename: file.name})

            form.setFieldValue("key", file.name);
            messageApi.success(`Llave privada '${file.name}' cargada correctamente.`);
        },
        beforeUpload: (file) => {
            setIsLoading({loading: true, message: 'Subiendo Archivo Llave Privada.'})
            let extension = file.name.split(".").pop();
            //todo: validar extension y otros mime types, file.type === "application/x-iwork-keynote-sffkey" &&
            const isKey = extension == "key";
            if (!isKey) {
                setIsLoading({loading: false, message: ''})
                messageApi.error(`El archivo '${file.name}' no es extensión *.key`);
                return false;
            }
            setIsLoading({loading: false, message: ''})
            return isKey || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            //if (info.file.status !== 'uploading') setIsLoading(true);
            if (info.file.status === 'done') setIsLoading({loading: false, message: ''});
            else if (info.file.status === 'error') {
                setIsLoading({loading: false, message: ''});
                messageApi.error(`El archivo '${info.file.name}' no pudo cargarse.`);
            }
        },
    };

    return (
        <div className='manifest-wrapper'>
            {contextHolder}
            <Form
                form={form}
                layout='vertical'
                onFinish={handleSubmit}
                autoComplete='off'
                style={{maxWidth: '330px', margin: 'auto'}}>
                <div className='header-form access-container'>
                    <img className='logo' src='/img/lunasoft-logo-01.png'></img>
                    <h1 className='title center-text'>Firmar Documentos</h1>
                    <p className='light-text justify-text' style={{marginTop: '12px'}}>
                        Completa la informaci&oacute;n para realizar la firma del
                        manifiesto y aceptaci&oacute;n de t&eacute;rminos de uso.
                    </p>
                </div>
                <Form.Item
                    label={
                        <span>
            Correos electrónicos <Tooltip title="Ingrese hasta 3 correos para el envío del manifiesto. El primer correo se usará para el registro y los otros dos serán para copia (CC).">
                <InfoCircleOutlined style={{ color: '#1890ff' }} />
            </Tooltip>
        </span>
                    }
                    required
                >
                    <Input.Group compact>
                        <Input
                            style={{ width: '3em', textAlign: 'center' }}
                            prefix={<MailOutlined />}
                        />
                        <Form.Item
                            name="emails"
                            noStyle
                            rules={[
                                {
                                    validator: (_, value) => {
                                        if (!value || value.length < 1) {
                                            return Promise.reject(new Error('Ingrese al menos un correo electrónico'));
                                        }
                                        if (value.length > 3) {
                                            return Promise.reject(new Error('No puede ingresar más de tres correos electrónicos'));
                                        }
                                        for (const email of value) {
                                            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                                                return Promise.reject(new Error('Por favor, ingrese correos electrónicos válidos'));
                                            }
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Select
                                style={{width:"calc(100% - 3em)"}}
                                mode="tags"
                                placeholder="Correo principal y hasta 2 en CC"
                                tokenSeparators={[',']}
                                onChange={emailChange}
                                open={false}
                                suffixIcon={null}
                                allowClear={true}
                                maxCount={3}
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item
                    label='Certificado público (*.cer)'
                    name='cer'
                    rules={[
                        {required: true, message: 'Favor de introducir su certificado público'},
                    ]}>
                    <div>
                        <Upload {...propsUploadCer}
                                accept=".cer,.CER"
                                showUploadList={false}>
                            <Button block icon={<UploadOutlined/>}>Solo archivos .cer </Button>
                        </Upload>
                        <p className='break-all'>{certificate.filename}</p>
                    </div>
                </Form.Item>
                <Form.Item
                    name='taxId'
                    hidden={!taxId}
                    value={taxId}>
                    <label>RFC: {taxId}</label>
                    <Input type="hidden" readOnly disabled/>
                </Form.Item>
                <Form.Item
                    label='Llave privada eFirma/Fiel (*.key)'
                    name='key'
                    rules={[
                        {required: true, message: 'Favor de introducir su llave privada eFirma/Fiel'},
                    ]}>
                    <div>
                        <Upload {...propsUploadKey}
                                accept=".key,.KEY"
                                showUploadList={false}>
                            <Button block icon={<UploadOutlined/>}>Solo archivos .key </Button>
                        </Upload>
                        <p className='break-all'>{privateKey.filename}</p>
                    </div>
                </Form.Item>
                <Form.Item
                    label='Contraseña llave privada'
                    name='password'
                    onChange={passwordChange}
                    rules={[
                        {required: true, message: 'Favor de introducir contraseña de llave privada eFirma/Fiel'},
                    ]}>
                    <Input.Password autoComplete="new-password" addonBefore={<SafetyOutlined/>}/>
                </Form.Item>
                <Form.Item>
                    <div className='center-button-login'>
                        <p>Favor de leer los documentos antes de firmar.</p>
                        <Button
                            className='mb-2'
                            icon={<EyeOutlined/>}
                            type='primary'
                            htmlType='button'
                            onClick={() => nextWizardStep()}
                            block
                            disabled={wizardStep == 2}>
                            {wizardStep == 2 ? 'Documentos Leídos' : 'Leer Siguiente Documento'}
                        </Button>
                        <Button
                            icon={<EditOutlined/>}
                            type='primary'
                            htmlType='submit'
                            block
                            disabled={hasFormErrors || wizardStep < 2}>
                            Firmar Documento
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ManifestForm;